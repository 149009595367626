import React, { useContext, useMemo } from 'react'
import LocalizedTransitionLink from '../../../LocalizedTransitionLink'
import {
  internationalRoutes,
  routeNames,
} from '../../../../utils/helpers/routes'
import SlideShow from '../../../Slideshow'
import { I18nextContext } from 'gatsby-plugin-react-i18next'

const ImagesShuffle = ({ uid, hoverImages, client, title, thumbnail, alt, hasPage }) => {
  const { language } = useContext(I18nextContext)
  const localizedRouteProject = useMemo(
    () => internationalRoutes[routeNames.projects].langs[language],
    [language]
  )
  
  const _slideshow = useMemo(() => (
    <>
      <SlideShow
        mainImage={thumbnail}
        images={hoverImages}
        fallbackAlt={alt}
      />
        {client && <p className="shuffle__text mb-0 mt-md-3 mt-2">{client}</p>}
        {title && <p className="shuffle__text c-gray-600">{title}</p>}
    </>
  ), [thumbnail, hoverImages, alt, client, title])

  return (
    <li role="presentation" className="shuffle__item">
      { hasPage ? 
        <LocalizedTransitionLink
          entryTransitionType="slideIn"
          exitTransitionType="none"
          to={`${localizedRouteProject}/${uid}`}
          aria-label={alt}
        >
        {_slideshow}
        </LocalizedTransitionLink>
        : 
        <div aria-label={alt}>
          {_slideshow}
        </div>
      }
    </li>
  )
}

export default ImagesShuffle
