import gsap from "gsap"
import { animateShuffleEntry, gsapParams, setShuffleEntry } from "./common"

const selectors = {
  title: '.page-404__hero h1',
  desc: '.page-404__hero p',
  projects: '.page-404__projects'
}

const setTitleEntry = () => {
  gsap.set(selectors.title, { ...gsapParams.fadeOutDown })
  gsap.set(selectors.desc, { ...gsapParams.fadeOutDown })
}

const animateTitleEntry = () => {
  const tl = gsap.timeline()
  tl.to(selectors.title, { ...gsapParams.fadeInUp }, 0)
  tl.to(selectors.desc, { ...gsapParams.fadeInUp }, .15)
  return tl
}

const animateEntry = () => {
  const tl = []
  tl.push(animateTitleEntry())
  tl.push(animateShuffleEntry(selectors.projects))

  return tl
}

const setEntry = () => {
  setTitleEntry()
  setShuffleEntry()
}

export {
  animateEntry,
  setEntry
}