import React, { useContext, useEffect, useMemo } from 'react'
import ClassicLayout from '../components/Layouts/ClassicLayout'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import ListShuffle from '../components/Lists/ListShuffle'
import LocalizedTransitionLink from '../components/LocalizedTransitionLink'
import { getPrismicEdges } from '../utils/helpers/transformers'

import { setEntry, animateEntry } from '../utils/page-animations/404'
import { AnimationStateContext } from '../utils/hooks/AnimationState'

const NotFoundPage = ({ data }) => {
  const { t } = useTranslation(['page-not-found'])

  const projects = useMemo(
    () => getPrismicEdges(data, 'prismicProjects'),
    [data]
  )
  const projectsList = useMemo(
    () => projects.filter((p) => p.data.has_page && !p.data.has_nda),
    [projects]
  )
  const { canPlayAnimation } = useContext(AnimationStateContext)

  useEffect(() => {
    setEntry()
  }, [])

  useEffect(() => {
    if (!canPlayAnimation) {
      return
    }
    const tls = animateEntry()
    return () => {
      tls.forEach((tl) => tl && tl.kill())
    }
  }, [canPlayAnimation])

  return (
    <ClassicLayout
      seo={{
        title: t('page-not-found:title'),
      }}
      name="404"
    >
      <section className="page-404__hero">
        <div className="container">
          <div className="row">
            <div className="col ft-center">
              <h1 className="h3">404</h1>
              <p className="mt-1 mt-md-0 mb-0 ft-default-m-small">
                {t('page-not-found:subtitle')}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="page-404__projects">
        <ListShuffle list={projectsList} showNbElements={3} disableShuffle />
        <div className="container">
          <div className="row mt-4 mt-md7">
            <div className="col ft-center">
              <LocalizedTransitionLink
                entryTransitionType="slideIn"
                exitTransitionType="none"
                to="/"
              >
                <button className="btn btn--center btn--dark btn--small border-w-4 ft-truncat">
                  {t('page-not-found:cta_back')}
                </button>
              </LocalizedTransitionLink>
            </div>
          </div>
        </div>
      </section>
    </ClassicLayout>
  )
}

export default NotFoundPage

export const query404 = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicProjects: allPrismicProject(
      filter: {
        lang: { eq: "fr-fr" }
        data: {
          has_nda: { ne: true }
          has_page: { eq: true }
          thumbnail: { url: { ne: null } }
        }
      }
    ) {
      ...prismicProject
    }
  }
`
