import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ImagesShuffle from './ImagesShuffle'
import { shuffleArray } from '../../../utils/helpers/utils'
import { iconShuffle } from '../../../utils/icons'

import ScrollTrigger from 'gsap/ScrollTrigger'

const ListShuffle = ({ list = [], showNbElements, disableShuffle }) => {
  const needsFiller = useMemo(() => list.length < 6, [list])
  const [shuffledList, setShuffledList] = useState(list)
  const [isInitialized, setIsInitialized] = useState(false)

  const handlePickUniqueClients = useCallback(
    (list, nb) => {
      if (needsFiller) {
        return list
      }
      const uniqList = []
      list.every((project) => {
        const isInList = uniqList.find(
          (uniqProject) =>
            uniqProject.data.client.document.data.name.text ===
            project.data.client.document.data.name.text
        )
        if (!isInList) {
          uniqList.push(project)
        }
        return uniqList.length < nb
      })
      return uniqList
    },
    [needsFiller]
  )

  const handleShuffleList = useCallback(() => {
    if (needsFiller) {
      return setShuffledList(list)
    }
    const listWithoutCurrentElements = list.filter(
      (listElement) =>
        !shuffledList.find(
          (currentElement) => currentElement.uid === listElement.uid
        )
    )
    setShuffledList(
      handlePickUniqueClients(
        shuffleArray(listWithoutCurrentElements),
        showNbElements || list.length
      )
    )
  }, [needsFiller, handlePickUniqueClients, shuffledList, list, showNbElements])

  const shuffledFillerList = useMemo(() => {
    const currentLength = shuffledList.length

    if (currentLength === 0) {
      return []
    }
    if (currentLength >= 6) {
      return shuffledList
    }

    const nbElements = showNbElements - currentLength
    const temp = [...shuffledList]

    for (let i = 0, j = 0; i < nbElements; i++) {
      temp.push(shuffledList[j])
      j++
      if (j === currentLength) {
        j = 0
      }
    }
    return temp
  }, [showNbElements, shuffledList])

  useEffect(() => {
    setShuffledList(
      handlePickUniqueClients(shuffleArray(list), showNbElements || list.length)
    )
    setIsInitialized(true)
  }, [list, showNbElements, handlePickUniqueClients])

  useEffect(() => {
    ScrollTrigger.refresh()
  }, [shuffledList])

  const classHide = isInitialized ? '' : 'c-alpha-0'
  return (
    <div className="container">
      <div className="shuffle">
        <ul className={classHide}>
          {shuffledFillerList &&
            shuffledFillerList.length > 0 &&
            shuffledFillerList.map((item, idx) => (
              <ImagesShuffle
                uid={item.uid}
                key={idx}
                index={idx}
                hasPage={item?.data.has_page}
                thumbnail={item?.data?.thumbnail}
                hoverImages={item?.data?.snapshots}
                alt={`${item?.data?.client?.document?.data?.name?.text} — ${item?.data?.name?.text} illustration`}
              />
            ))}
        </ul>
      </div>
      {!disableShuffle && (
        <div className="ft-center mt-4 mt-md-5 shuffle__cta">
          <button
            className="btn btn--dark btn--small border-w-4 btn--side-padding-32"
            onClick={handleShuffleList}
          >
            <span>Shuffle</span>
            <span className="btn__icon btn__icon--hover">{iconShuffle}</span>
          </button>
        </div>
      )}
    </div>
  )
}

export default ListShuffle
