import React from 'react'

const getRingPoints = ({
  cx = 0,
  cy = 0,
  r = 10,
  progress = 1 / 3,
  start = -0.25,
} = {}) => {
  const n = Math.ceil(24 * progress)
  const array = [{ x: cx, y: cy }]

  for (let i = 0; i <= n; i++) {
    const a = 2 * Math.PI * (start + (progress * i) / n || 0)
    const x = cx + r * Math.cos(a)
    const y = cy + r * Math.sin(a)
    array.push({ x, y })
  }

  return array.map(({ x, y }) => `${x.toFixed(1)},${y.toFixed(1)}`).join(' ')
}

class Pie extends React.Component {
  static defaultProps = {
    size: 30,
    opacity: 1,
    progress: 1 / 3,
    color: '#A2A2A2',
    r: 7,
    backgroundR: 10,
    backgroundColor: '#171717',
    backgroundOpacity: 0.75,
  }

  state = { ...this.props }

  get progress() {
    return this.state.progress
  }
  set progress(progress) {
    this.setState({ progress })
  }

  get opacity() {
    return this.state.opacity
  }
  set opacity(opacity) {
    this.setState({ opacity })
  }

  render() {
    const {
      size,
      progress,
      opacity,
      color,
      r,
      withBackground,
      backgroundR,
      backgroundColor,
      backgroundOpacity,
    } = this.state
    return (
      <svg
        className="nano pie"
        width={size}
        height={size}
        fill={color}
        opacity={opacity}
      >
        {withBackground && (
          <circle
            {...{
              cx: size / 2,
              cy: size / 2,
              r: backgroundR,
              fill: backgroundColor,
              opacity: backgroundOpacity,
            }}
          />
        )}
        <polygon
          points={getRingPoints({ cx: size / 2, cy: size / 2, r, progress })}
        />
      </svg>
    )
  }
}

export default Pie
