import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import PrismicPicture from '../PrismicPicture'
import { isTouchDevice } from '../../utils/helpers/utils'
import Pie from '../AnimatedIcons/Pie'
import { ease1, easeY } from '../../utils/constants/eases'

const Slideshow = ({ mainImage: propMainImage, images, fallbackAlt }) => {
  const refSlideshow = useRef(null)
  const refHoverImages = useRef(null)
  const refProgressIndicator = useRef(null)
  const refProgressIndicatorNb = useRef(null)
  const refPie = useRef(null)
  const refTimelineEnter = useRef(null)
  const refTimelineReplaceImage = useRef(null)
  const refIdxMainImage = useRef(2)
  const [mainImage1, setMainImage1] = useState(null)
  const [mainImage2, setMainImage2] = useState(propMainImage)
  const [indexHoverImage, setIndexHoverImage] = useState(null)

  const handleMouseEnter = useCallback(() => {
    if (isTouchDevice() || !refHoverImages.current || !refSlideshow.current)
      return null
    gsap.to(refSlideshow.current, {
      duration: 0.4,
      scale: 1.04,
      ease: ease1,
    })
    gsap.fromTo(
      refHoverImages.current,
      {
        y: 40,
        duration: 0.4,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.3,
        ease: ease1,
      }
    )
    if (refProgressIndicator.current) {
      refTimelineEnter.current = gsap
        .timeline()
        .set(
          refProgressIndicatorNb.current,
          {
            opacity: 0,
          },
          0
        )
        .to(
          refProgressIndicator.current,
          {
            opacity: 1,
            duration: 0.4,
            ease: ease1,
          },
          0
        )
        .to(
          refPie.current,
          {
            opacity: 1,
            ease: easeY,
          },
          0
        )
        .fromTo(
          refPie.current,
          { progress: 0 },
          { duration: 0.6, progress: 1 },
          0
        )
        .to(
          refPie.current,
          {
            duration: 0.3,
            opacity: 0,
          },
          0.6
        )
        .to(
          refProgressIndicatorNb.current,
          {
            opacity: 1,
            duration: 0.2,
          },
          0.6
        )
    }
  }, [])
  const handleMouseLeave = useCallback(() => {
    if (isTouchDevice() || !refHoverImages.current || !refSlideshow.current)
      return null

    gsap.to(refHoverImages.current, {
      opacity: 0,
      duration: 0.3,
      ease: ease1,
    })
    gsap.to(refSlideshow.current, {
      duration: 0.4,
      scale: 1,
      ease: ease1,
    })
    if (refProgressIndicator.current) {
      gsap.to(refProgressIndicator.current, {
        opacity: 0,
        ease: ease1,
      })
    }
    if (refTimelineEnter.current) {
      refTimelineEnter.current.kill()
    }
    setIndexHoverImage(null)
  }, [])
  const handleMouseMove = useCallback(
    (event) => {
      if (
        isTouchDevice() ||
        images.length === 0 ||
        !refSlideshow.current ||
        !refHoverImages.current
      )
        return null
      const { clientX, clientY } = event
      const slideshowRect = refSlideshow.current.getBoundingClientRect()
      const { width, height, top, left } = slideshowRect
      let { x, y } = slideshowRect
      if (typeof x === 'undefined') {
        x = left
      }
      if (typeof y === 'undefined') {
        y = top - height
      }

      const ratio = (clientX - x) / width
      let index = Math.floor(images.length * ratio)
      index = Math.min(index, images.length - 1)
      index = Math.max(index, 0)
      const indicatorX = (clientX - x + 12) / 1.03
      const indicatorY = (clientY - y) / 1.03

      if (refProgressIndicator.current) {
        gsap.to(refProgressIndicator.current, {
          x: indicatorX,
          y: indicatorY,
          ease: ease1,
        })
      }
      if (indexHoverImage !== index) {
        if (indexHoverImage !== null) {
          const oldImage = refHoverImages.current.querySelector(
            `.slideshow__image_${indexHoverImage}`
          )
          if (oldImage) {
            gsap.set(oldImage, { zIndex: 1 })
          }
        }
        const newImage = refHoverImages.current.querySelector(
          `.slideshow__image_${index}`
        )
        const comeFromX =
          30 * (!indexHoverImage || index > indexHoverImage ? 1 : -1)
        if (newImage) {
          gsap.set(newImage, { opacity: 0, zIndex: 3 })
          gsap.fromTo(
            newImage,
            { x: comeFromX, opacity: 0, duration: 0.5 },
            { x: 0, y: 0, opacity: 1, duration: 0.5, ease: ease1 }
          )
        }
      }
      setIndexHoverImage(index)
    },
    [indexHoverImage, images]
  )
  const handleAnimationFadeMainImage = useCallback(
    (tl, oldImageNode, newImageNode) => {
      if (oldImageNode) {
        tl.set(oldImageNode, { zIndex: 2 }, 0.01)
        tl.set(oldImageNode, { opacity: 0 }, 0.71)
      }
      if (newImageNode) {
        tl.set(newImageNode, { zIndex: 3, opacity: 0 }, 0)
        tl.to(
          newImageNode,
          {
            opacity: 1,
            duration: 0.6,
            ease: ease1,
          },
          0.1
        )
      }
    },
    []
  )
  const handleLoadImg = useCallback(() => {
    if (refTimelineReplaceImage.current) {
      refTimelineReplaceImage.current.play()
    }
  }, [])

  useEffect(() => {
    if (!refSlideshow.current) return null
    if (refTimelineReplaceImage.current) {
      refTimelineReplaceImage.current.kill()
    }
    refTimelineReplaceImage.current = gsap.timeline()
    const tl = refTimelineReplaceImage.current
    if (refIdxMainImage.current === 1) {
      const oldImageNode =
        refSlideshow.current.querySelector('.slideshow__main1')
      const newImageNode =
        refSlideshow.current.querySelector('.slideshow__main2')
      handleAnimationFadeMainImage(tl, oldImageNode, newImageNode)
      setMainImage2(propMainImage)
      refIdxMainImage.current = 2
    } else {
      const newImageNode =
        refSlideshow.current.querySelector('.slideshow__main1')
      const oldImageNode =
        refSlideshow.current.querySelector('.slideshow__main2')
      handleAnimationFadeMainImage(tl, oldImageNode, newImageNode)
      setMainImage1(propMainImage)
      refIdxMainImage.current = 1
    }
    return () =>
      refTimelineReplaceImage.current && refTimelineReplaceImage.current.kill()
  }, [handleAnimationFadeMainImage, propMainImage])

  useEffect(() => {
    const _refTimelineEnter = refTimelineEnter.current
    const _refTimelineReplaceImage = refTimelineReplaceImage.current
    return () => {
      _refTimelineEnter && _refTimelineEnter.kill()
      _refTimelineReplaceImage && _refTimelineReplaceImage.kill()
    }
  }, [])

  return (
    <div
      ref={refSlideshow}
      className="slideshow"
      role="presentation"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <p ref={refProgressIndicator} className="progress-indicator">
        <Pie ref={refPie} color="white" r={8} opacity={1} withBackground />
        <span ref={refProgressIndicatorNb} className="progress-indicator__nb">
          {`${indexHoverImage + 1}/${images?.length}`}
        </span>
      </p>
      {mainImage1 && (
        <PrismicPicture
          className="slideshow__main slideshow__main1"
          type="fixed"
          image={{ ...mainImage1, alt: mainImage1.alt || fallbackAlt }}
          onLoad={handleLoadImg}
        />
      )}
      {mainImage2 && (
        <PrismicPicture
          className="slideshow__main slideshow__main2"
          type="fixed"
          image={{ ...mainImage2, alt: mainImage2.alt || fallbackAlt }}
          onLoad={handleLoadImg}
        />
      )}
      <div ref={refHoverImages} className="slideshow__hover">
        {images &&
          images.map(({ image }, idx) => (
            <PrismicPicture
              className={`slideshow__image slideshow__image_${idx}`}
              type="fixed"
              image={{ ...image, alt: image.alt || fallbackAlt }}
              key={idx}
            />
          ))}
      </div>
    </div>
  )
}

export default memo(Slideshow)
